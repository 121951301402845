<template>
  <div>
    <head-layout :head-title="$t('cip.dc.CJudgmentTask.title.CJudgmentTask')" :head-btn-options="headBtnOptions"  @head-add="addFn">
    </head-layout>
    <div class="headers" style="display: flex">
      <el-input
        :placeholder="$t('cip.dc.CJudgmentTask.title.CJudgmentTask')"
        v-model="listParms.judgeName"
        class="hinput"
        size="mini"
        style="width: 200px"
      />
      <el-button-group style="margin-left: 10px">
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-search"
          @click="onLoad"
        ></el-button>
        <el-button
          size="mini"
          icon="reset-refresh icon-refresh"
          @click="czFn"
        ></el-button>
      </el-button-group>
    </div>

    <grid-layout
      ref="gridLayout"
      :data-total="total"
      @page-size-change="handleSizeChange"
      @page-current-change="handleCurrentChange"
      @page-refresh-change="onLoad"
      :page="page"
      @gird-handle-select-click="handleSelectionChange"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :gridRowBtn="gridRowBtn"
      @grid-view="toSee"

      @grid-romve="toDel"
      @grid-execute="toExecute2"
      :searchColumns="searchColumns"
    >
    <!-- @grid-execute="toExecute" -->
      <template slot="runState" slot-scope="{ row }">
        {{ row.runState == 1 ?  $t('omit.executioning')  : row.runState == 0 ?  $t('omit.paused') : row.runState}}
      </template>

      <template #customBtn="{row}">
        <el-button @click="toExecute(row)"
                   size="small"
                   type="text">
                   {{row.runState == 1 ? $t('datacenter.dataQuality.monitoringTask.pause') :$t('omit.firing')}}
                   <!-- {{row.runState == 1 ? $t('datacenter.dataQuality.monitoringTask.pause') : $t('datacenter.dataQuality.monitoringTask.implement')}} -->
        </el-button>
      </template>

    </grid-layout>

    <el-dialog
    :title="$t('cip.dc.DComission.tip')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>{{dialogtext}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('cip.dc.DComission.false') }}</el-button>
        <el-button type="primary" @click="SureToExecute">{{ $t('cip.dc.DComission.sure') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { judgeList,executeSchedule,delJudge} from "@/api/dataAcquisition/index";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";

export default {
  components: {
    HeadLayout,
    GridLayout,
  },
  props: {},
  data() {
    return {
      total:'',
      dialogtext:'',
      runState:"",
      btnType:'',
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0
      },
      dialogVisible: false,
      tableLoading: false,
      ExecuteId: "",
      tableData: [],
      listParms: {
        pageNum: 1,
        pageSize: 10,
        judgeName: "",
      },
      gridRowBtn: [
        // {
        //   label: this.$t("cip.cmn.btn.editBtn"),
        //   emit: "grid-edit",
        //   remark: "edit",
        //   type: "text",
        //   icon: "",
        // },

        {
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "grid-view",
          remark: "view",
          type: "text",
          icon: "",
        },
        // {
        //   label: this.$t("sinoma.btn.execute"),
        //   emit: "grid-execute",
        //   remark: "execute",
        //   type: "text",
        //   icon: "",
        // },
        {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "grid-romve",
          remark: 'remove',
          type: "text",
          icon: ""
        },
        {
          label: this.$t('datacenter.dataQuality.monitoringTask.implement'),
          emit: "grid-execute",
          remark: 'execute',
          type: "text",
          icon: ""
        },

      ],

     headBtnOptions: [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
        },
      ],

      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 200,
        column: [
          {
            label:this.$t('cip.dc.CJudgmentTask.title.JudgmentTask'),
            prop: "judgeName",
            cell: true,
          },
          {
            label:this.$t('cip.dc.CJudgmentTask.field.state'),
            prop: "runState",
            cell: true,
            slot:true

          },
           {
            label:this.$t('cip.dc.CJudgmentTask.field.remarks'),
            prop: "remark",
            cell: true,
          },

        ],
      },
    };
  },

  methods: {

    //获取列表
    getList(parms) {
      judgeList(parms).then((res) => {
        this.tableData = res.data.data.dataList;
        this.total = res.data.data.page.totalSize
      });
    },
    onLoad(){
      this.getList(this.listParms)
    },
    czFn(){
      this.listParms.pageNum = 1
      this.listParms.pageSize = 10
      this.listParms.judgeName = ""
      this.getList(this.listParms)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.listParms.pageSize = e.pageSize
      this.getList(this.listParms)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.listParms.pageNum = e.currentPage
      this.getList(this.listParms)
    },




    //查看
    toSee(item) {
      this.$router.push({
        path: "/Quality/judgeTaskAdd",
        query: {
          type: "see",
          id:item.id
        },
      });
    },

    addFn(){
     this.$router.push({
        path: "/Quality/judgeTaskAdd",
        query: {
          type: "add",
        },
      });
    },
    //执行和删除

    toExecute(item) {
      if(item.runState==0){
        this.dialogtext = this.$t('cip.dc.DComission.msg1')
        this.btnType = 'execute'
      }
      if(item.runState==1){
        this.dialogtext = this.$t('cip.dc.DComission.msg2')
        this.btnType = 'stop'
      }
      this.dialogVisible = true;
      this.ExecuteId = item.id;

      this.runState = item.runState

    },

    toExecute2(item){
      this.dialogtext = this.$t('cip.dc.DComission.msg1')
        this.btnType = 'zx'
        this.dialogVisible = true;
      this.ExecuteId = item.id;
      this.runState = item.runState
    },



    SureToExecute() {
      this.dialogVisible = false;

      if(this.btnType == 'zx'){
         executeSchedule({ id: this.ExecuteId,executeState:"3" }).then((res) => {
          this.getList(this.listParms)
        this.$message({
          message: this.$t('cip.dc.DComission.msg4'),
          type: "success",
        });
      });
      }

      if(this.btnType == 'execute'){
         executeSchedule({ id: this.ExecuteId,executeState:"1" }).then((res) => {
          this.getList(this.listParms)
        this.$message({
          message: this.$t('cip.dc.DComission.msg4'),
          type: "success",
        });
      });
      }
      if(this.btnType == 'stop'){
         executeSchedule({ id: this.ExecuteId,executeState:"2" }).then((res) => {
          this.getList(this.listParms)
        this.$message({
          message: this.$t('cip.dc.DComission.msg5'),
          type: "success",
        });
      });
      }
      if(this.btnType == 'del'){
         delJudge({id:this.ExecuteId}).then(res=>{
          this.$message({
          message: this.$t('cip.dc.offlineDComission.delmsg'),
          type: "success",
        });
        this.getList(this.listParms)
         })
      }

    },
    toDel(item){
      this.dialogVisible = true;
      this.dialogtext = this.$t('cip.dc.qualityDComission.msg1')
      this.ExecuteId = item.id;
      this.btnType = 'del'

    }
  },
  mounted() {
    this.getList(this.listParms);
  },
};
</script>
<style  scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}
</style>
